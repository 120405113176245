import(/* webpackMode: "eager" */ "/home/runner/work/httptoolkit-website/httptoolkit-website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/httptoolkit-website/httptoolkit-website/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/httptoolkit-website/httptoolkit-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-dmSans\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"dmSansFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledLink","StyledButton","StyledButtonWrapper"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/components/elements/button/button.styles.ts");
;
import(/* webpackMode: "eager", webpackExports: ["MovingBorder"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/components/elements/moving-border/index.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/components/layout/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RadixProviders"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/components/layout/radix-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledLayout"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/components/layout/styled-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/httptoolkit-website/httptoolkit-website/src/styles/styled-component-registry.tsx");
